@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

$green: #84bd00;
$lightgreen: #98d01f;

@mixin mobile {
    @media (max-width: 767px) {
      @content;
    }
  }

*, *::before, *::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}


input, button, textarea, select {
    font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

#root, #__next {
    isolation: isolate;
}

html {
    width: 100%;
    height: 100%;
}

body {
    background: #000000;
    color: #FFFFFF;
    font-family: "Ubuntu", sans-serif;
    text-align: center;
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
}

main {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    position: relative;
}

.header-link {
    position: absolute;
    top: 0;
    right: 10%;
    background: $green;
    border-radius: 0 0 5px 5px;
    padding: 65px 12px 15px;
    img {
        width: 28px;
        height: auto;
        display: block;
    }
}

.wrapper {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    opacity: 0;
}

.logo {
    width: 100%;
    height: auto;
    display: block;
    margin-left: 20px;
}

h1, h2 {
    text-transform: uppercase;
    font-size: 36px;
    line-height: 1.2;
    @include mobile {
        font-size: 27px;
    }
}

.controls {
    width: 100%;
    height: 80px;
    padding-top: 15px;
}

.country-picker {
    display: flex;
    column-gap: 30px;
    width: 100%;
    justify-content: center;
    button {
        background: none;
        padding: 0;
        border: none;
        cursor: pointer;
        transition: transform 0.3s;
        &:hover {
            transform: scale(1.05);
        }
    }
    img {
        width: 75px;
        height: auto;
        display: block;
        border-radius: 5px;
    }
}

#view-1 {
    opacity: 1;
    .btn {
        display: none;
    }
}

.btn {
    background: $green;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    font-size: 22px;
    padding: 15px;
    border-radius: 10px;
    width: calc(100% - 30px);
    &:hover {
        background: $lightgreen;
        transform: scale(1.05);
    }
}

#view-2 {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 45px;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: -20px 0px,20px 0px;
    animation: l18 1s infinite;
}
@keyframes l18{ 
    25%  {box-shadow: -20px -20px, 20px 20px}
    50%  {box-shadow:   0px -20px,  0px 20px}
    75%  {box-shadow:  20px -20px,-20px 20px}
    100% {box-shadow:  20px   0px,-20px  0px}
}

#view-3 {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    h2 {
        font-size: 42px;
    }
    .btn {
        padding-left: 30px;
        padding-right: 30px;
        width: auto;
        animation: pulse-green 3s infinite;
    }
}


.animated {
    animation-duration: 1.5s;
    animation-iteration-count: 1;
  }
  
  @keyframes tada {
    0% {
      transform: scale3d(1, 1, 1);
    }
  
    10%, 20% {
      transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
  
    30%, 50%, 70%, 90% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
  
    40%, 60%, 80% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
  
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
  
  .tada {
    animation-name: tada;
  }

  @keyframes pulse-green {
    0% {transform: scale(0.95); box-shadow: 0 0 0 0 rgba(132, 189, 0, 0.7)}  
    70% {transform: scale(1); box-shadow: 0 0 0 10px rgba(132, 189, 0, 0)}
    100% {transform: scale(0.95); box-shadow: 0 0 0 0 rgba(132, 189, 0, 0)}
  }